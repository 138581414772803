import React from "react"
import { Link } from "gatsby"

import {
  ContactSection,
  ImageGrid,
  InteriorBanner,
} from "../components/elements"
import Layout from "../components/layouts/Layout"
import { SEO, Section } from "../components/utilities"

import pic1 from "../images/pool1.jpg"
import banner from "../images/pool2.jpg"
import pic3 from "../images/pool3.jpg"
import pic2 from "../images/pool4.jpg"
import { Color } from "../components/utilities"

const imageArr = [
  {
    image: pic1,
    altTag: "Kids swimming in pool with inflatable pool rings.",
  },
  {
    image: pic3,
    altTag: "Woman relaxing on inflatable pool ring in swimming pool.",
  },
  {
    image: pic2,
    altTag: "Young boy relaxing and enjoying time in a swimming pool.",
  },
]

const PoolServicePage = () => (
  <Layout>
    <SEO
      title="Swimming Pool Repair & Services Ohio"
      description="Local luxury pool contractors providing pool maintenance, repair, and closing services in Toledo and Northwest Ohio area"
    />
    <InteriorBanner image={banner}>
      <h1 style={{ lineHeight: "125%" }}>
        Expert Pool Services in <br /> Toledo, Ohio
      </h1>
    </InteriorBanner>
    <Section>
      <p>
        Welcome to Hunt Pools & Service, your trusted partner for all your
        inground pool needs in Perrysburg, Monclova, Maumee, Sylvania, Ottawa
        Hills, and the surrounding Toledo, Ohio area. Our team of experienced
        technicians is dedicated to ensuring your swimming pool is always in top
        condition, whether it's opening season, repairs, or conversions.
      </p>
      <br />
      <h2>Pool Opening Services</h2>
      <p>
        As the weather warms up and spring approaches, it's time to prepare your
        backyard oasis for the upcoming swimming season. Our pool opening
        services are designed to make this process seamless for you. From
        removing the cover to starting up the filtration system, our technicians
        handle every aspect with precision. We go beyond the basics by providing
        thorough pool cleaning and balancing of water chemistry. If any issues
        arise during the process, such as with the pool liner, pump, or heater,
        rest assured that we will diagnose and offer repair options to ensure
        your pool is ready for endless hours of enjoyment.
      </p>
      <br />
      <br />
      <h2>Pool Repairs</h2>
      <p>
        Pool leaks can quickly turn your summer fun into a headache. Our skilled
        technicians and state-of-the-art equipment are here to locate and
        diagnose any leaks in your pool. Whether it's underground plumbing,
        damaged fittings, liner issues, or cracks in the structure, we have the
        expertise to fix it. From liner repairs to pump replacements, we offer
        comprehensive repair solutions to keep your pool in pristine condition
        throughout the season.
      </p>
      <br />
      <br />
      <h2>Pool Closings</h2>
      <p>
        Properly closing your pool at the end of the swimming season is crucial
        for its long-term health and performance. Our team meticulously follows
        each step of the process to ensure a hassle-free experience for you.
        From removing debris and sanitizing the pool to storing equipment and
        securing the cover, we leave no stone unturned. With our expertise, you
        can trust that your pool will be protected during the offseason,
        minimizing the risk of costly repairs down the road.
      </p>
      <br />
      <br />
      <h2>Saltwater Conversions for Inground Pools</h2>
      <p>
        Are you thinking about converting from a traditional chlorine pool to a
        saltwater pool? Our experts are here to guide you through the conversion
        process. Saltwater pools offer numerous benefits, including easier
        maintenance and gentler water for sensitive skin. Our team will work
        closely with you to balance your pool's chemistry, install the
        chlorinator with precision, and ensure proper salt levels for optimal
        performance. Experience the joys of a saltwater pool with our seamless
        conversion services.
        <br />
        <br />
        At Hunt Pools & Service, we're committed to providing unparalleled
        service and expertise to our valued customers in Toledo Ohio, and
        beyond. Trust us to keep your pool in pristine condition year-round.{" "}
        <Link to="/contact-us/" style={{ color: Color.primary }}>
          Contact us today to schedule your pool service appointment
        </Link>
        .
      </p>
    </Section>
    <ImageGrid images={imageArr} />
    <ContactSection>
      Call Hunt's Pools & Service to discuss your inground pool maintenance and
      pool repair needs. We will help you keep your pool looking and functioning
      at the highest level possible.
    </ContactSection>
  </Layout>
)

export default PoolServicePage
